if (window.FFF === undefined) {
    window.FFF = {};
}

if (!window.DM) {
    window.DM = {
        Log: {
            log: function () {
                console.log.apply(null, arguments);
            }
        }
    };
}

var pageCriteria = DM.molFePageMetadata && DM.molFePageMetadata.getPageCriteria() || {};

//Fake geo US when using localhost-us or liveint-us.andintweb.dmgt.net
if (window.location.hostname === 'liveint-us.andintweb.dmgt.net' || window.location.hostname === 'localhost-us') {
    typeof pageCriteria !== 'undefined' && pageCriteria ? pageCriteria.geo = 'US' : window.FFF.parent.pageCriteriaGeo = 'US';
}

var getGeoUrlParameter = function (geo) {
    return geo && geo.toLowerCase() === 'us' ? '&geo=us' : '&geo=gb';
};

var getPageCriteriaArticleId = function () {
    return pageCriteria.articleId || window.FFF.parent.articleId;
};

var getPageCriteriaGeo = function () {
    return pageCriteria.geo && pageCriteria.geo.toLowerCase() || window.FFF.parent.pageCriteriaGeo.toLowerCase();
};

var onImageError = function (img) {
    img.setAttribute('src', 'https://i.dailymail.co.uk/i/furniture/fff/hub/placeholder.png');
    img.setAttribute('onerror', '');
};

var getFFFInlineTrackingMetadata = function () {
    var fffPartialInline = document.getElementsByClassName('fff-partial');
    var fffMobileInline = document.getElementsByClassName('fff-m-inline');
    var fffFullInline = document.getElementsByClassName('fff-inline');
    if (fffPartialInline && fffPartialInline.length) {
        return {type: 'inline-partial', selector: '.fff-inline .fff-popup', fffInline: fffPartialInline[0]};
    } else if (fffMobileInline && fffMobileInline.length) {
        return {type: 'inline', selector: '.fff-m-inline.fff-m-popup', fffInline: fffMobileInline[0]};
    } else if (fffFullInline && fffFullInline.length) {
        return {type: 'inline', selector: '.fff-inline .fff-popup', fffInline: fffFullInline[0]};
    }
    return null;
};

window.FFF.getFFFInlineTrackingMetadata = getFFFInlineTrackingMetadata;
window.FFF.getGeoUrlParameter = getGeoUrlParameter;
window.FFF.getPageCriteriaArticleId = getPageCriteriaArticleId;
window.FFF.getPageCriteriaGeo = getPageCriteriaGeo;
window.FFF.onImageError = onImageError;
